import React, { Component } from "react";
import TylipaContract from "./contracts/Tylipa.json";
import getWeb3 from "./utils/getWeb3";
import loading from './loadingSVG';
import Button from '@material-ui/core/Button';
import metamask_img from "./assets/download-metamask-dark.png"

class Web3Component extends Component {

  state = {
      myImg: "",
      web3: null,
      accounts: null,
      contract: null,
      contractAddress: null,
      error: false,
      dna: null,
      fee: null,
      networkId: 0,
      networkType: 0,
      canMint: false,
      myTokens: [],
      myTokenImgs: [],
      blockExpURI: "http://etherscan.io/address/",
  };

  componentDidMount = async () => {
    var error = false;

    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();
      this.setState({ web3 });
      await this.initNetwork();

    } catch (err) {
      // Catch any errors for any of the above operations.
      error = "noweb3";
      this.setState({ error });
      console.log("no web3");
      console.log(this.state.web3);
      console.log(err);
    }

    if (!error) {

      await this.initAccounts();

      try {
        if(await this.initContract()) {
          await this.initUserdata();
          var me = this;
          var accountInterval = setInterval(async () =>  {
            const account = await me.state.web3.eth.getAccounts();

            if (account[0] !== me.state.accounts[0]) {
              me.setState( {accounts: account});
              if (me.state.web3.eth.net.getId() !== me.state.networkId) {
                console.log("network changed");
              }
              me.initUserdata();
              console.log("accounts changed");
            }
          }, 2000);
        } else {
          return true;
        }

      } catch (error) {
        this.setState({ error:"othererror" });
        console.error(error);
      }
    }
  };

  initAccounts = async () => {
    // Use web3 to get the user's accounts.
    const accounts = await this.state.web3.eth.getAccounts();
    this.setState({ accounts });
  }

  initNetwork = async () => {
    // get network info
    const networkId = await this.state.web3.eth.net.getId();
    const networkNames = {
      "1" : "main",
      "3" : "ropsten",
      "4" : "rinkeby",
      "5" : "goerli",
      "42" : "kovan",
      "5777": "private",
    }
    const networkType = networkNames[networkId];
    if (networkId > 1 && networkId < 100) {
      this.setState({
        blockExpURI: "http://" + networkType + ".etherscan.io/address/",
      })
    }
    this.setState({
      networkId,
      networkType,
    });

    console.log("we are on network " + networkId + " (" + networkType+ ")");
  }

  initContract = async () => {
    // Get the contract instance and addresses
    const deployedNetwork = TylipaContract.networks[this.state.networkId];
    if ( deployedNetwork ) {
      const instance = new this.state.web3.eth.Contract(
        TylipaContract.abi,
        deployedNetwork && deployedNetwork.address,
      );

      this.setState({
          contractAddress: deployedNetwork.address,
          contract: instance
        }
      );
      return true;
    } else {
      console.log("this is the wrong net (not deployed here)");
      this.setState({ error:"wrongnet" });
      return false;
    }
  }

  initUserdata = async () => {
    const { accounts, contract } = this.state;

    const isOwner = await contract.methods.isOwner().call({ from: accounts[0] });
    this.setState({ isOwner });
    if (isOwner) {
      console.log("is Owner");
      this.setState({ fee:0 });
    } else {
      const fee =  await contract.methods.getMintingFee().call();
      this.setState({ fee });
    }

    const canMint = await contract.methods.canMint(accounts[0]).call();
    this.setState({ canMint });

    const mintWaitTime = await contract.methods.mintWaitTime(accounts[0]).call();
    this.setState({ mintWaitTime });

    const beneficiary = await contract.methods.getBeneficiary().call();
    this.setState({ beneficiary });

    const totalSupply = await contract.methods.totalSupply().call();
    this.setState({ totalSupply });

    const myTokens = await contract.methods.tokensOfOwner(accounts[0]).call();
    this.setState({ myTokens});

    if (myTokens.length) this.loadTokenImages();
  };

  loadTokenImages = async () => {
    var images = this.state.myTokenImgs;
    for (var i in this.state.myTokens) {
      if (!images.hasOwnProperty(i) ) images.push(loading);
    }
    this.setState( { myTokenImgs: images})

    for (i in this.state.myTokens) {
      if (images[i]===loading) {
        images[i] = await this.state.contract.methods.tokenImg(this.state.myTokens[i]).call();
        this.setState( { myTokenImgs: images})
      }
      console.log("img #" + this.state.myTokens[i] + " loaded");
    }
  }

  grow = async () => {
    if (this.state.canMint) {
      const response = await this.state.contract.methods.mint().send(
        {
          from: this.state.accounts[0],
          value: this.state.fee,
        }
      );
      console.log(response);
      this.initUserdata();
    }
  }

  render() {

    if (!this.state.web3) {
      return (
        <div className="w3block">
          <div className="w3infblock sm">
            Loading Web3, accounts, and contract...<br />
            <br />
            Do you have <a href="https://metamask.io/">MetaMask</a> installed? <br />
            You need it to grow your own!<br />
          </div>
        </div>
      );
    }

    if (this.state.error === "noweb3") {
      return (
        <div className="w3block">
          <div className="w3infblock sm">
            Could not connect to Ethereum network<br />
            <a href="https://metamask.io/" className="no"><img width="240" src={metamask_img} alt="Install MetaMask"/></a>
          </div>
        </div>
      )
    } else if(this.state.error === "wrongnet") {
      return (
        <div className="w3block">
          <div className="w3infblock sm">
            <p className="sm">You are connected to Ethereum { this.state.networkType } network (ID: { this.state.networkId})</p>
            <button>Wrong Network!</button>
          </div>
        </div>
      )
    } else if (this.state.error) {
      return (
        <div className="w3block">
          <div className="w3infblock sm">
            Failed to connect to Ethereum network. <br />
            Are you sure you have <a href="https://metamask.io/">MetaMask</a> installed?<br />
            Check console for details.<br />
          </div>
        </div>
      )
    }

    return (
      <div className="w3block">
        <div className="w3infblock sm">
          <p className="sm">You are connected to Ethereum { this.state.networkType } network (ID: { this.state.networkId})</p>

          <div>

            { this.state.mintWaitTime < 1 &&
              <Button variant="contained" onClick={this.grow}>Grow your own!</Button>
            }

            { this.state.mintWaitTime > 0 &&
              <Button variant="contained">Patience! Seed ready in {(this.state.mintWaitTime / (3600*24)).toFixed(0)} days</Button>
              }
          </div>

          <div className="block">
            ŦɏŁᵻⱣⱥ is a Non-Fungible Token, it's contract lives here:
            <a className="addr" href={this.state.blockExpURI + "/" + this.state.contractAddress } target="_blank" rel="noopener noreferrer">
              { this.state.contractAddress }</a> <br />


            Only one flower per wallet adress and week can be grown.<br />
            Everything comes with a fee, the growing fee is  { this.state.fee / 1000000000000000000 } ether. <br />
            50 % of the growing fee are directly donated to <a href="https://350.org/" target="_blank" rel="noopener noreferrer">350.org</a> at
              <a className="addr" href={this.state.blockExpURI + "/" + this.state.beneficiary } target="_blank" rel="noopener noreferrer">{ this.state.beneficiary }</a><br />
            Currently out in the wild plains of Ethereum: { this.state.totalSupply } Flower(s).
          </div>
        </div>

        <div>----</div>

        <h3>Your garden</h3>
        <div className="block">


            { this.state.myTokens && (this.state.myTokens.map( (d,i) => (
              <div className="cblock" key={i}>
              <div className="sm">#{d}</div>
              <div className="innerSVG" dangerouslySetInnerHTML={
                { __html: this.state.myTokenImgs[i] }
              } />
              </div>
            )))}

            { this.state.myTokens.length === 0 && "is empty" }
        </div>

      </div>
    );
  }
}

export default Web3Component;
