import React, { Component } from 'react';
import * as Web3Utils from 'web3-utils';

import logo from './logo.png';
import './App.css';

import Web3Component from './Web3Component'
import Address2SVG from './Address2SVG'


class App extends Component {
  constructor() {
    super();
    this.state = {
      addr: Web3Utils.randomHex(20),
      showOneFullScreen: false,
      size: Math.min(window.innerHeight,window.innerWidth)
   };
   if (window.location.hash === '#run') {
     this.setState({ addr:  Web3Utils.randomHex(20), showOneFullScreen: true });
   }
  }

  growOne = () => {
    this.setState({ addr:  Web3Utils.randomHex(20), showOneFullScreen: true });
  }

  closeOne = () => {
    this.setState({ showOneFullScreen: false });
  }

  render() {

      return (
        <div className="App">
          { (this.state.showOneFullScreen) && (
            <div className="full">
              <button onClick={ this.closeOne } className="close"> &#x2715; </button>
              <Address2SVG addr={ this.state.addr } size={this.state.size} randomize="3000" />
            </div>
          ) }
          { (window.location.hash === '#run') && (
            <div className="full hidemouse">
              <Address2SVG addr={ this.state.addr } size={this.state.size} randomize="3000" />
            </div>
          ) }
          { (!this.state.showOneFullScreen && window.location.hash !== '#run') && (
            <div>
              <img src={logo} alt="logo" height="40" /><br />
              <div className="line" />

              <div className="svg left"><Address2SVG className="left" addr={ Web3Utils.randomHex(20)} small randomize="4000" /></div>
              <div className="svg"><Address2SVG addr={ this.state.addr } size="320" randomize="3000" /></div>
              <div className="svg right"><Address2SVG className="right" addr={ Web3Utils.randomHex(20) } small randomize="5000"/></div>

              <div>
                <h3>About</h3>
                <div className="block">
                  <p>ŦɏŁᵻⱣⱥ is a unique NFT contract that was set alive in 2019.
                 ŦɏŁᵻⱣⱥ is autonomous code that produces artful flowers on the blockchain.
                 Any Ethereum wallet address can grow an unlimited number of unique flowers,
                 however, growing takes time: genesis can only happen once in seven days.
                  And like everything else in this world, it costs a fee. </p>

                  <p>These flowers are not just images, they are generated by and on the blockchain itself.
                 ŦɏŁᵻⱣⱥs have adapted to the limited conditions of memory and processing power of
                  the distributed 'world computer' and developed simple, yet beautiful forms.
                   These are the very first flowers of a decentralized crypto world! </p>

                  <p>We all know that transactions powered by proof-of-work consume
                  enormous amounts of energy. So be aware that by creating artificial
                  flowers on the blockchain you help speed up climate change and related
                  natural disasters. Don't despair: You are creating a new Flora,
                  don't mind the old! Besides ŦɏŁᵻⱣⱥ's offsets that by distributing 50%
                   of its minting and resale fees directly to 350.org as encoded
                   in the contract (the other half goes to its genius creator). </p>

                  <p> ŦɏŁᵻⱣⱥ is the tulip mania for the coming Dark Ages of the 21st century!
                  When the flowers in the wild start to wither and die,
                  we just replace and grow them on the blockchain.<br />
                   Isn't this what the blockchain was invented for?</p>
                </div>
              </div>

              <div>----</div>

              <Web3Component />

              <div>----</div>

              <div className="block">
                <p>
                  <a href="https://v2.tylipa.art" className="fuck">Fuck the Blockchain! Just give me a Print!</a>
                </p>
              </div>

              <div>----</div>

              <div className="block">
                <a href="https://opensea.io/assets/tylpa" title="Trade on OpenSea" target="_blank" rel="noopener noreferrer" className="no" >
                  <img width="160" src={require('./assets/opensea.png')} alt="Buy on OpenSea badge" />
                </a>
              </div>

              <footer fixed="true">
                An Experiment by <a href="http://m.ash.to">m.ash.to</a> &copy; 2019 -- <a href="https://gitlab.com/backface/tylipa  ">source code</a>
              </footer>
            </div>
          ) }
        </div>
      );
  }
}

export default App;
