import React, { Component } from 'react';
import * as Web3Utils from 'web3-utils';

class Address2SVG extends Component {

  constructor(props){
    super(props)
    if (this.props.small) {
      this.width = 160;
      this.scx = 3;
      this.sr = 1.5;
      this.scr = 0.5;
    } else {
      this.width = 320;
      this.scx = 6;
      this.sr = 3;
      this.scr = 1;
    }
    if (this.props.size) {
      this.width = parseFloat(this.props.size);
      this.scx = this.width / 50;
      this.sr = this.width / 100.0;
      this.scr = this.width / 320;
    }
    this.state = { addr: this.props.addr };
	}

  componentDidMount(){
    if (this.props.randomize) {
      this.genNewAddress()
    }
  }

  genNewAddress() {
    this.setState({
      addr: Web3Utils.randomHex(20)
    });
    setTimeout(this.genNewAddress.bind(this), this.props.randomize);
  }

	render () {
		var addr = this.state.addr;
    if (!this.props.randomize)
      addr = this.props.addr;

    if (addr === 0 || addr === null) {
      return "";
    }

    if (addr.slice(0, 2) === "0x") {
      addr = addr.substr(2);
    }

    var ints = [];
		var fl= [];

    for (var i=0; i < 24; i++) {
			ints.push(parseInt(addr[i], 16))
		}

		for (i=0; i < ints.length; i+=6) {
			fl.push( { x:ints[i]||1, y: ints[i+1]||1, n: Math.max(ints[i+2],4), r: ints[i+3]||2, g: ints[i+4]||3, b: ints[i+5]||3 } );
		}

		return (
				<svg width={this.width } height={this.width}>
						{
							fl.map( (d,i) => (
								<g key={i}> {
								[...Array(d.n)].map( (z, j) => (
									<g key={j}
                    transform={"translate("
                    + (this.width/2) + ","
                    	+ (this.width/2) + ")"}>

                    <ellipse key={ i + "-" + j}
											transform={"rotate(" + ((j % d.n) * (360/d.n)) + ")"}
											cx={d.x * this.scx}
											rx={((d.x + 1) * this.sr) }
											ry={((d.y + 1) * this.sr)}
											fill={"rgb(" + (d.r*16) + "," + (d.g * 10) + "," + (d.b* 10) +")"}
											style={{opacity:0.3}}
										/>

									</g>
								))
								}</g>
							))
						}
					<circle
						cx={(this.width/2)}
						cy={(this.width/2)}
						style={{opacity:0.7}}
						r={Math.max(ints[ints.length-1] * this.scr, 1)}
						fill="yellow"
					/>
				</svg>

    );
  }
}


export default (Address2SVG)
